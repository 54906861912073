import { Metadata, Nft } from '@metaplex-foundation/js'
import { useWallet } from '@solana/wallet-adapter-react'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { useCallback, useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import { MintButton } from '../components/MintButton'
import { useMetaplex } from '../hooks/useMetaplex'

const BOOKS = new Map([
  [
    '3JzapWFkDYo2h44XP3ZJ64bRhpW8Vbc7TPqdt6eHmveC',
    {
      name: 'Crypto Boy' as const,
      img: 'https://m.media-amazon.com/images/I/61ZxzWCesLL.jpg',
      collection: {
        address: '3JzapWFkDYo2h44XP3ZJ64bRhpW8Vbc7TPqdt6eHmveC',
      },
      uri: 'https://book-minting-demo.pages.dev/cb.json',
    },
  ],
  [
    '32YUMN9uWTniVPMqbFS5CHAGvG7TF1q2YFcXmFH7APYi',
    {
      name: 'The Perfect Immigrant' as const,
      img: 'https://m.media-amazon.com/images/I/51oJLn0pDwL._SX331_BO1,204,203,200_.jpg',
      collection: {
        address: '32YUMN9uWTniVPMqbFS5CHAGvG7TF1q2YFcXmFH7APYi'
      },
      uri: 'https://book-minting-demo.pages.dev/tpi.json',
    },
  ],
])

const BOOK_COLLECTION_ADDRESSES = Object.freeze(Array.from(BOOKS.keys()))

function isNftOrMetadataModel(obj: unknown): obj is Nft | Metadata {
  if (obj == null) {
    return false
  }

  return (obj as any).model === 'nft' || (obj as any).model === 'metadata'
}

export default function MintPage() {
  const [isConfettiShown, setShowConfetti] = useState(false)
  const [readBook, setReadBook] = useState<
    null | 'Crypto Boy' | 'The Perfect Immigrant'
  >(null)
  const [walletAccessNfts, setWalletAccessNfts] = useState<
    Array<Nft | Metadata>
  >([])
  const [walletAccessCollections, setWalletAccessCollections] = useState<
    string[]
  >([])
  const { publicKey: walletPublicKey } = useWallet()
  const metaplex = useMetaplex()

  const displayConfetti = useCallback(() => {
    setShowConfetti(true)
  }, [])

  useEffect(() => {
    if (isConfettiShown) {
      const timeout = setTimeout(() => setShowConfetti(false), 6_000)
      return () => clearTimeout(timeout)
    }
  }, [isConfettiShown])

  const checkWalletNfts = useCallback(() => {
    if (walletPublicKey == null) {
      setWalletAccessCollections([])
      setWalletAccessNfts([])
      setReadBook(null)
      return
    }

    metaplex
      .nfts()
      .findAllByOwner({
        owner: walletPublicKey,
      })
      .then((walletNfts) => {
        if (walletNfts.length === 0) {
          setWalletAccessNfts([])
          return
        }

        const bookAccessNfts = walletNfts
          .filter(isNftOrMetadataModel)
          .filter(
            (nft) =>
              BOOK_COLLECTION_ADDRESSES.includes(nft.collection?.address.toBase58() ?? '')
          )
          .reduce((acc, nft) => {
            const collectionAddress = nft.collection?.address.toBase58()
            if (!collectionAddress) {
              return acc
            }
            acc.set(nft.collection?.address.toBase58(), nft)
            return acc
          }, new Map())
        setWalletAccessNfts(Array.from(bookAccessNfts.values()))
        setWalletAccessCollections(Array.from(bookAccessNfts.keys()))
      })
  }, [
    walletPublicKey,
    setWalletAccessNfts,
    setWalletAccessCollections,
    metaplex,
  ])

  useEffect(() => {
    checkWalletNfts()
  }, [checkWalletNfts])

  const handleSuccessfulMint = useCallback(() => {
    checkWalletNfts()
    displayConfetti()
  }, [checkWalletNfts, displayConfetti])

  if (readBook === 'Crypto Boy') {
    return (
      <div className="App">
        <section className="App-header">
          <button className="App-button" onClick={() => setReadBook(null)}>
            Back to All Books
          </button>
          <h1>Crypto Boy by Ian Halperin</h1>
          <h2>Chapter One</h2>
          <p>
            June, 23, 2021 - I'm having breakfast at a boutique hotel called
            Leblon in Medellin, Colombia...
          </p>
        </section>
      </div>
    )
  }

  if (readBook === 'The Perfect Immigrant') {
    return (
      <div className="App">
        <section className="App-header">
          <button className="App-button" onClick={() => setReadBook(null)}>
            Back to All Books
          </button>
          <h1>The Perfect Immigrant by Miran Vucic</h1>
          <h2>Prologue</h2>
          <p>
            Miami Beach. Spring, 2020 - The world is in turnoil. As I sit with
            my wife and dog in the comfort of our 16th floor condo...
          </p>
        </section>
      </div>
    )
  }

  return (
    <div className="App">
      <Confetti run={isConfettiShown} recycle={false} />
      <section className="App-header">
        <h1>Bookshelf</h1>
        <WalletMultiButton />
        <p>get access to books with crypto</p>
        <div className="App-grid">
          {Array.from(BOOKS.values()).map((book) => {
            if (walletAccessCollections.includes(book.collection.address ?? '')) {
              return (
                <div className="App-grid__item">
                  <img className="App-book__img" src={book.img} />
                  <div className="App-book__title">{book.name}</div>
                  <button
                    className="App-button"
                    onClick={() => setReadBook(book.name)}
                  >
                    Read
                  </button>
                </div>
              )
            }

            return (
              <div className="App-grid__item">
                <img className="App-book__img" src={book.img} />
                <div className="App-book__title">{book.name}</div>
                <MintButton
                  collectionAddress={book.collection.address}
                  name={book.name}
                  uri={book.uri}
                  onSuccess={handleSuccessfulMint}
                />
              </div>
            )
          })}
        </div>
      </section>
    </div>
  )
}
