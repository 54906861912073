import { useState } from 'react'
import { useWallet } from '@solana/wallet-adapter-react'
import {
  PublicKey,
} from '@metaplex-foundation/js'
import {
  WalletMultiButton,
} from '@solana/wallet-adapter-react-ui'
import { useMetaplex } from '../../hooks/useMetaplex'

type Props = {
  uri: string
  name: string
  collectionAddress?: string
  onSuccess?: () => void
}

export function MintButton(props: Props) {
  const [isMinting, setIsMinting] = useState(false)
  const { connected: isWalletConnected, } = useWallet()
  const metaplex = useMetaplex()

  const handleMintClick = async () => {
    try {
      setIsMinting(true)


      await metaplex.nfts().create({
        isCollection: typeof props.collectionAddress === 'string',
        collection: props.collectionAddress ? new PublicKey(props.collectionAddress) : undefined,
        uri: props.uri,
        name: props.name,
        sellerFeeBasisPoints: 100_00, // Represents 100%
      })

      setIsMinting(false)

      if (typeof props.onSuccess === 'function') {
        props.onSuccess()
      }
    } catch (err) {
      console.error(err)
    } finally {
      setIsMinting(false)
    }
  }

  if (!isWalletConnected) {
    return <WalletMultiButton>Connect</WalletMultiButton>
  }

  return (
    <button
      className="App-button"
      onClick={handleMintClick}
      disabled={isMinting}
    >
      {isMinting ? 'Minting...' : 'Mint NFT and Read'}
    </button>
  )
}
