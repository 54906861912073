import { useMemo } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Metaplex, walletAdapterIdentity } from '@metaplex-foundation/js';

export function useMetaplex() {
  const { connection } = useConnection();
  const walletContext = useWallet();
  return useMemo(
    () => Metaplex.make(connection).use(walletAdapterIdentity(walletContext)),
    [walletContext, connection]
  );
}

